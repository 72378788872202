import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CustomActionModal,
  CustomModal,
  Loader,
  MeetingMinutes,
  MeetingNotesShareSetting,
  RatingModal,
  Reminders,
  Sidebar,
  Summary,
  TabBar,
  Transcription,
} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { eventExtraActions, generalExtraActions } from 'reduxStore/actions';
import {
  COMPLETED,
  FAILED,
  FILE_SOURCE,
  FILE_TYPES,
  IN_PROGRESS,
  IN_QUEUE,
  PENDING,
  SUMMARY_SHARING_CODES,
  TAB_CODES,
} from 'common/constants';
import { routes, utils } from 'common';
import Rating from 'components/rating/Rating';
import { getAudioTranscriptions } from 'pages/meetings/helpers';
import 'pages/meetings/_meetings.scss';

function Meetings() {
  const location = useLocation();
  const rating = location.state?.rating;
  const [showRatingModal, setShowRatingModal] = useState(!!rating);
  const {id} = useParams();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {transcriptionData, summaryData, originalSummaryData, loading} = useSelector((state) => state.general);
  const {loading: eventLoading} = useSelector((state) => state.event);

  const [currentTab, setCurrentTab] = useState(tab ? parseInt(tab) : TAB_CODES.MOM);
  const [absoluteIndex, setAbsoluteIndex] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [query, setQuery] = useState('');
  const [meetingTypes, setMeetingTypes] = useState([]);
  const [editSummary, setEditSummary] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [error, setError] = useState('');
  const [settings, setSettings] = useState();
  const [showSharingOptions, setShowSharingOptions] = useState(false);
  const [showOriginalSummary, setShowOriginalSummary] = useState(false);

  const {event_settings, summary_status, mom_status} = transcriptionData || {};
  const isOrganizer = event_settings?.is_organizer;
  const isSummaryInProgress = summary_status === IN_PROGRESS || summary_status === IN_QUEUE;
  const isSummaryCompleted = summary_status === COMPLETED;
  const isMOMInProgress = mom_status === IN_PROGRESS || mom_status === IN_QUEUE;
  const isMOMPending = mom_status === PENDING;
  const isMOMCompleted = mom_status === COMPLETED;

  useEffect(() => {
    setSettings({
      selectedSharingOption: transcriptionData.event_settings?.summary_share_option,
      selectedEmails: transcriptionData.event_settings?.summary_share_emails,
      selectedDomains: transcriptionData.event_settings?.summary_share_domains,
      isOrganizer: transcriptionData.event_settings?.is_organizer,
    });
  }, [transcriptionData.event_settings]);

  const handleDelete = () => {
    try {
      dispatch(generalExtraActions.deleteTranscription({id: id})).then((response) => {
        if (response?.type === 'general/transcriptionDelete/fulfilled') {
          getAudioTranscriptions(dispatch, query, meetingTypes);
          navigate(routes.MEETINGS);
        }
      });
    } catch (error) {
      console.log(error);
    }
    setShowDeleteModal(false);
  };

  const saveSummary = (summary) => {
    try {
      dispatch(
        generalExtraActions.transcriptionSummaryEdit({
          id: id,
          data: {summary: summary},
        })
      ).then((response) => {
        if (response?.type === 'general/transcriptionSummaryEdit/fulfilled') {
          dispatch(generalExtraActions.audioTranscriptDetail(id));
          setEditSummary(false);
          setShowOriginalSummary(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSummarySave = () => {
    saveSummary(JSON.stringify(utils.sanitizeSummaryContent(summaryData)));
  };

  const handleSummaryRevert = (id) => {
    saveSummary(originalSummaryData);
  };

  const handleShowOriginalSummary = (id) => {
    setShowOriginalSummary(true);
    setEditSummary(false);
  }

  const handleSummaryCancel = () => {
    setEditSummary(false);
  };

  const handleSummaryEdit = () => {
    if (editSummary) {
      setEditSummary(false);
    } else {
      setEditSummary(true);
    }
  };

  const handleShare = () => {
    if (
      settings.selectedSharingOption === SUMMARY_SHARING_CODES.CUSTOM &&
      settings.selectedEmails.length === 0
    ) {
      setError('Enter email adddress for sharing meeting notes');
      return;
    }

    if (
      settings.selectedSharingOption === SUMMARY_SHARING_CODES.DOMAIN_WISE_ATTENDEES &&
      settings.selectedDomains.length === 0
    ) {
      setError('Enter domains for specifying invitees for sharing meeting notes');
      return;
    }

    const eventSetting = transcriptionData?.event_settings;
    try {
      dispatch(
        eventExtraActions.settings({
          id: eventSetting.event_id,
          data: {
            summary_format: eventSetting.summary_format,
            summary_share_option: settings.selectedSharingOption,
            summary_share_emails: settings.selectedEmails,
            summary_share_domains: settings.selectedDomains,
            auto_share_summary: eventSetting.auto_share_summary,
            auto_generate_mom: eventSetting.auto_generate_mom,
            recurrence: eventSetting.is_recurring ? transcriptionData.start_at : undefined,
          },
        })
      ).then((response) => {
        setShowShareModal(false);
        if (response?.type === 'auth/settings/fulfilled') {
          dispatch(generalExtraActions.meetingNotesShare(id)).then((response) => {
            if (response?.type === 'general/meetingNotesShare/fulfilled') {
              toast.success('Meeting notes shared');
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTranscriptionExport = () => {
    utils.generateTranscriptionPDF(transcriptionData);
  };

  const handleMOMExport = () => {
    utils.generateMOMPDF(transcriptionData);
  };

  const handleGenerateTranscript = async () => {
    try {
      dispatch(generalExtraActions.audioRetranscribe(id)).then((response) => {
        if (response?.type === 'general/audioRetranscribe/fulfilled') {
          getAudioTranscriptions(dispatch, query, meetingTypes);
          dispatch(generalExtraActions.audioTranscriptDetail(id));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenerateSummary = async () => {
    try {
      dispatch(generalExtraActions.transcriptionSummaryCreate(id)).then((response) => {
        if (response?.type === 'general/transcriptionSummaryCreate/fulfilled') {
          getAudioTranscriptions(dispatch, query, meetingTypes);
          dispatch(generalExtraActions.audioTranscriptDetail(id));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenerateMOM = async () => {
    try {
      dispatch(generalExtraActions.transcriptionMOMCreate(id)).then((response) => {
        if (response?.type === 'general/transcriptionMOMCreate/fulfilled') {
          getAudioTranscriptions(dispatch, query, meetingTypes);
          dispatch(generalExtraActions.audioTranscriptDetail(id));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportSummary = async () => {
    utils.generateSummaryPDF(transcriptionData);
  };

  const getCurrentTabComponent = () => {
    if (!id) {
      return (
        <div className='no-transcription'>Select the Meeting from sidebar to view Details</div>
      );
    }
    if (currentTab === TAB_CODES.TRANSCRIPTION) {
      return (
        <Transcription id={id} absoluteIndex={absoluteIndex} setAbsoluteIndex={setAbsoluteIndex}/>
      );
    } else if (currentTab === TAB_CODES.SUMMARY) {
      return <Summary editSummary={editSummary} showOriginalSummary={showOriginalSummary}/>;
    } else if (currentTab === TAB_CODES.MOM) {
      return <MeetingMinutes id={id} query={query} meetingTypes={meetingTypes}/>;
    } else {
      return <Reminders id={id}/>;
    }
  };

  const getCurrentTabControls = () => {
    if (!id) {
      return null;
    }
    if (currentTab === TAB_CODES.TRANSCRIPTION) {
      return (
        <div className='controls'>
          <button className='danger-btn' onClick={() => setShowDeleteModal(true)}>
            Delete
          </button>
          {(transcriptionData?.transcription_status === PENDING ||
            transcriptionData?.transcription_status === FAILED) && (
            <button className='action-btn' onClick={handleGenerateTranscript}>
              Retry
            </button>
          )}
          {transcriptionData?.transcription_status === COMPLETED && (
            <button className='action-btn' onClick={handleTranscriptionExport}>
              Export
            </button>
          )}
        </div>
      );
    } else if (currentTab === TAB_CODES.SUMMARY) {
      return (
        <div className='controls'>
          {isOrganizer && !isSummaryInProgress &&
            (editSummary ? (
              <>
                {!showOriginalSummary && <>
                  <button disabled={loading} className='action-btn' onClick={handleSummarySave}>
                    Save
                  </button>
                  <button className='simple-btn' onClick={handleSummaryCancel}>
                    Cancel
                  </button>
                </>}
              </>
            ) : (
              showOriginalSummary ?
                <>
                  <button
                    className='action-btn'
                    onClick={() => {
                      setShowOriginalSummary(false);
                    }}
                  >
                    View Current
                  </button>
                  {/*<button*/}
                  {/*  className='action-btn'*/}
                  {/*  onClick={handleSummaryRevert}*/}
                  {/*>*/}
                  {/*  Revert back*/}
                  {/*</button>*/}
                </> :
                isSummaryCompleted && <button className='simple-btn' onClick={handleSummaryEdit}>
                  Edit
                </button>
            ))}
          {!editSummary && !isSummaryInProgress && !showOriginalSummary && (
            <>
              {transcriptionData?.original_summary && (
                <button className='action-btn' onClick={handleShowOriginalSummary}>
                  View Original
                </button>
              )}
              <button className='action-btn' onClick={handleGenerateSummary}>
                {isSummaryCompleted ? 'Re-generate' : 'Generate'}
              </button>
              {isSummaryCompleted && (
                <button className='action-btn' onClick={handleExportSummary}>
                  Export
                </button>
              )}
            </>
          )}
          {isOrganizer && !editSummary && !showOriginalSummary && !isSummaryInProgress &&
            transcriptionData?.file_source !== FILE_SOURCE.UPLOAD && (
              <button className='action-btn' onClick={() => setShowShareModal(true)}>
                Share
              </button>
            )}
        </div>
      );
    } else if (currentTab === TAB_CODES.MOM) {
      return <div className='controls'>
        {!isMOMInProgress && !isMOMPending && <button className='action-btn' onClick={handleGenerateMOM}>
          {isSummaryCompleted ? 'Re-generate' : 'Generate'}
        </button>}
        {isMOMCompleted && (
          <button className='action-btn' onClick={handleMOMExport}>
            Export
          </button>
        )}
      </div>
    } else {
      return null;
    }
  };

  useEffect(() => {
    getAudioTranscriptions(dispatch, query, meetingTypes);
  }, [query, meetingTypes]);

  useEffect(() => {
    setEditSummary(false);
    setShowOriginalSummary(false);
    if (id) {
      dispatch(generalExtraActions.audioTranscriptDetail(id));
      setCurrentTab(TAB_CODES.SUMMARY);
    }
  }, [id]);

  return (
    <div className='meetings'>
      {(loading || eventLoading) && <Loader/>}
      <Sidebar
        currentId={id}
        absoluteIndex={absoluteIndex}
        query={query}
        meetingTypes={meetingTypes}
        setQuery={setQuery}
        setMeetingTypes={setMeetingTypes}
        currentTab={currentTab}
      />
      <div className='content'>
        {id ? (
          <>
            <div className='content-header'>
              <div className='title'>
                <span>{transcriptionData?.title}</span>
              </div>
              {getCurrentTabControls()}
            </div>
            <div className='meta-data'>
              {transcriptionData?.file_type !== undefined && (
                <span className='type'>{FILE_TYPES[transcriptionData?.file_type]}</span>
              )}
              {!loading && <div className='summary-rating'>
                <Rating rating={transcriptionData?.summary_rating} size={16} id={transcriptionData?.id}
                        setShowRatingModal={setShowRatingModal}/>
              </div>}
            </div>
            <TabBar currentTab={currentTab} setCurrentTab={setCurrentTab}/>
            {getCurrentTabComponent()}
          </>
        ) : (
          <div className='no-transcription'>Select the Meeting from sidebar to view Details</div>
        )}
      </div>
      <CustomModal
        show={showDeleteModal}
        title='Confirmation'
        description={`Are you sure you want to delete "${transcriptionData?.title}"?`}
        handleClose={() => setShowDeleteModal(false)}
        confirmBtnTitle='Delete'
        handleCancel={() => setShowDeleteModal(false)}
        handleConfirm={handleDelete}
      />
      <RatingModal
        rating={rating || transcriptionData?.summary_rating}
        show={showRatingModal}
        handleClose={() => setShowRatingModal(false)}
      />
      <CustomActionModal
        title={'Share meeting notes Email with'}
        show={showShareModal}
        onClose={() => {
          setShowSharingOptions(false);
          setShowShareModal(false);
        }}
        onClick={() => {
          setShowSharingOptions((prevState) => {
            if (prevState === true) {
              return false;
            }
          });
        }}
        body={
          <MeetingNotesShareSetting
            active={settings?.isOrganizer}
            error={error}
            setError={setError}
            settings={settings}
            setSettings={setSettings}
            showOptions={showSharingOptions}
            setShowOptions={setShowSharingOptions}
          />
        }
        footer={
          <button disabled={loading || eventLoading} className='standard-btn' onClick={handleShare}>
            {'Share'}
          </button>
        }
      />
    </div>
  );
}

export default Meetings;
