import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import generalExtraActions from '../actions/general';
import { utils } from '../../common';

const INITIAL_STATE = {
  loading: false,
  error: null,
  transcriptionData: {},
  originalTranscriptionData: {},
  audioTranscriptions: [],
  transcriptionSummaries: [],
  summaryData: {},
  originalSummaryData: {},
  summaryDataCopy: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    resetGeneralSlice: () => INITIAL_STATE,
    resetData: (state, { payload }) => {
      state.transcriptionData = state.originalTranscriptionData = state.summaryData = {};
    },
    updateTranscriptionSegmentText: (state, { payload }) => {
      const { index, updatedText } = payload;
      const transcription = state.transcriptionData.original_transcription;
      transcription[index].text = updatedText;
      state.transcriptionData.original_transcription = transcription;
    },
    updateTranscriptionSegmentSpeaker: (state, { payload }) => {
      const { index, speaker } = payload;
      const transcription = state.transcriptionData.original_transcription;
      transcription[index].speaker = speaker;
      state.transcriptionData.original_transcription = transcription;
    },
    mergeTranscriptionSegments: (state, { payload }) => {
      const { indices } = payload;
      if (indices.length < 2) return;
      state.transcriptionData.original_transcription = utils.mergeSegmentsByIndices(
        state.transcriptionData.original_transcription,
        indices
      );
    },
    removeTranscriptionSegments: (state, { payload }) => {
      const { indices } = payload;
      state.transcriptionData.original_transcription = utils.removeSegmentsByIndices(
        state.transcriptionData.original_transcription,
        indices
      );
    },
    cancelTranscriptionChanges: (state) => {
      state.transcriptionData = state.originalTranscriptionData;
    },
    cancelSummaryChanges: (state) => {
      state.summaryData = state.summaryDataCopy;
    },
    updateTranscriptionSummary: (state, { payload }) => {
      const { newSummaryData } = payload;
      state.summaryData = newSummaryData;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generalExtraActions.audioTranscriptList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.audioTranscriptions = payload;
    });
    builder.addCase(
      generalExtraActions.transcriptionSummaryList.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.transcriptionSummaries = payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.deleteTranscription.fulfilled,
        generalExtraActions.rating.fulfilled,
        generalExtraActions.transcriptionSummaryEdit.fulfilled,
        generalExtraActions.meetingNotesShare.fulfilled,
        generalExtraActions.transcriptionSummaryCreate.fulfilled,
        generalExtraActions.transcriptionMOMCreate.fulfilled,
      ),
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.audioTranscriptDetail.fulfilled,
        generalExtraActions.audioTranscriptionUpdate.fulfilled,
        generalExtraActions.audioRetranscribe.fulfilled,
      ),
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        const transcriptionData = payload;
        transcriptionData.speakerMap = utils.toArrayMapping(payload.speakers);
        state.transcriptionData = state.originalTranscriptionData = transcriptionData;
        state.summaryData = state.summaryDataCopy = utils.parseJSONData(transcriptionData.summary);
        if (transcriptionData?.original_summary) {
          state.originalSummaryData = utils.parseJSONData(transcriptionData?.original_summary);
        }
      }
    );
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.audioTranscriptList.pending,
        generalExtraActions.audioTranscriptDetail.pending,
        generalExtraActions.audioRetranscribe.pending,
        generalExtraActions.audioTranscriptionUpdate.pending,
        generalExtraActions.transcriptionSummaryList.pending,
        generalExtraActions.transcriptionSummaryCreate.pending,
        generalExtraActions.transcriptionMOMCreate.pending,
        generalExtraActions.deleteTranscription.pending,
        generalExtraActions.rating.pending,
        generalExtraActions.transcriptionSummaryEdit.pending,
        generalExtraActions.meetingNotesShare.pending,
      ),
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.audioTranscriptList.rejected,
        generalExtraActions.audioTranscriptDetail.rejected,
        generalExtraActions.audioRetranscribe.rejected,
        generalExtraActions.audioTranscriptionUpdate.rejected,
        generalExtraActions.transcriptionSummaryList.rejected,
        generalExtraActions.transcriptionSummaryCreate.rejected,
        generalExtraActions.transcriptionMOMCreate.rejected,
        generalExtraActions.deleteTranscription.rejected,
        generalExtraActions.rating.rejected,
        generalExtraActions.transcriptionSummaryEdit.rejected,
        generalExtraActions.meetingNotesShare.rejected,
      ),
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const { actions: generalActions, reducer } = authSlice;
export default reducer;
