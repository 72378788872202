import React from 'react';
import 'components/toggle/_toggle.scss';
import { IconDisableMic, IconEnableMic } from "assets/icons";

function Toggle({onChange, isDisabled = false, size = 48}) {

  const height = size * 0.6; // Adjust height dynamically
  const sliderSize = height - 8; // Keep some padding inside the toggle
  const sliderTranslate = size - height - 1; // Distance to slide

  const handleToggle = () => {
    onChange(!isDisabled);
  };

  return (
    <label
      className={`toggle ${isDisabled ? '' : 'active'}`}
      style={{width: `${size}px`, height: `${height}px`}}
    >
      <input type="checkbox" checked={!isDisabled} onChange={handleToggle}/>
      <span
        className='toggle-slider'
        style={{
          width: `${sliderSize}px`,
          height: `${sliderSize}px`,
          transform: isDisabled ? 'translateX(0)' : `translateX(${sliderTranslate}px)`,
        }}
      >
        <img src={isDisabled ? IconDisableMic : IconEnableMic} alt='mic'/>
      </span>
    </label>
  );
}

export default Toggle;