import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'api';

const generalExtraActions = {
  audioTranscriptList: createAsyncThunk('general/audioTranscriptList', api.audioTranscriptList),
  audioTranscriptDetail: createAsyncThunk(
    'general/audioTranscriptDetail',
    api.audioTranscriptDetail
  ),
  audioTranscriptionUpdate: createAsyncThunk(
    'general/audioTranscriptionUpdate',
    api.audioTranscriptionUpdate
  ),
  transcriptionSummaryList: createAsyncThunk(
    'general/transcriptionSummaryList',
    api.transcriptionSummaryList
  ),
  transcriptionSummaryCreate: createAsyncThunk(
    'general/transcriptionSummaryCreate',
    api.transcriptionSummaryCreate
  ),
  transcriptionSummaryEdit: createAsyncThunk(
    'general/transcriptionSummaryEdit',
    api.transcriptionSummaryEdit
  ),
  meetingNotesShare: createAsyncThunk(
    'general/meetingNotesShare',
    api.meetingNotesShare
  ),
  audioRetranscribe: createAsyncThunk('general/audioRetranscribe', api.audioRetranscribe),
  deleteTranscription: createAsyncThunk(
    'general/transcriptionDelete',
    api.audioTranscriptionDelete
  ),
  rating: createAsyncThunk('general/rating', api.rating),

  // MOM
  transcriptionMOMCreate: createAsyncThunk(
    'general/transcriptionMOMCreate',
    api.transcriptionMOMCreate
  ),
};

export default generalExtraActions;
