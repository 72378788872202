import { useDispatch, useSelector } from 'react-redux';
import 'components/meetingMinutes/_meetingMinutes.scss';
import { utils } from 'common';
import React, { useRef, useState } from 'react';
import { IconChevronDown, IconChevronUp } from 'assets/icons';
import { COMPLETED, IN_PROGRESS, IN_QUEUE } from 'common/constants';
import { ImgMOM } from "assets/images";
import { generalExtraActions } from "reduxStore/actions";
import { getAudioTranscriptions } from "pages/meetings/helpers";


function MOMSection({title, body}) {
  const [isOpen, setIsOpen] = useState(true);
  const contentRef = useRef(null);

  const getMOMItemsToRender = (data) => {
    if (!data) {
      return null;
    } else if (Array.isArray(data)) {
      return data.map((item) => {
        return getMOMItemsToRender(item);
      });
    } else if (typeof data === 'object') {
      return Object.keys(data).map((key, index) => (<div key={index} className='mom-sub-text'>
        <span className='mom-head'>{utils.snakeToTitleCase(key)}:</span>
        {getMOMItemsToRender(data[key])}
      </div>));
    } else {
      return <p className='mom-sub-text'>{data}</p>;
    }
  };

  return (body ? <div className='mom-section'>
    <div className='section-head' onClick={() => setIsOpen(!isOpen)}>
      <div className='section-title'>
        <h5>{utils.snakeToTitleCase(title)}</h5>
      </div>
      <div className='section-controls'>
          <span className='dropdown-icon'>
          <img src={isOpen ? IconChevronUp : IconChevronDown} alt='toggle'/>
        </span>
      </div>
    </div>
    <div ref={contentRef} className={`section-body ${isOpen ? 'open' : ''}`}>
      {getMOMItemsToRender(body)}
    </div>
  </div> : null);
}

export default function MeetingMinutes({id, query, meetingTypes}) {
  const dispatch = useDispatch();
  const {transcriptionData} = useSelector((state) => state.general);

  const isCompleted = transcriptionData?.mom_status === COMPLETED;
  const isInProgress = transcriptionData?.mom_status === IN_QUEUE || transcriptionData?.mom_status === IN_PROGRESS;


  const handleGenerateMOM = async () => {
    try {
      dispatch(generalExtraActions.transcriptionMOMCreate(id)).then((response) => {
        if (response?.type === 'general/transcriptionMOMCreate/fulfilled') {
          getAudioTranscriptions(dispatch);
          dispatch(generalExtraActions.audioTranscriptDetail(id));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    isCompleted ?
      <div className='mom'>
        <div className='mom-sections'>
          {transcriptionData?.mom && Object.entries(utils.parseJSONData(transcriptionData?.mom))?.map(([key, value]) => {
            return <MOMSection key={key} title={key} body={value}/>
          })}
        </div>
      </div> :
      !isInProgress && <div className='mom-empty'>
        <img src={ImgMOM}/>
        <h3>Capture key discussions effortlessly</h3>
        <p>Generate meeting minutes with a click or enable auto-generation for future meetings in settings.</p>
        <button className='action-btn' onClick={handleGenerateMOM}>Generate minutes of meeting</button>
      </div>
  )
}
