import { API_BASE_URL } from 'config';

const V1 = `${API_BASE_URL}/api/v1`;
const USERS = `${V1}/users`;
const CORE = `${V1}/core`;
const EVENTS = `${V1}/events`;

export const endpoints = {
  USER_DETAIL: (id) => `${USERS}/${id}/`,
  LOGIN: `${USERS}/login/`,
  REFRESH_TOKEN: (id) => `${USERS}/${id}/refresh-token/`,
  GOOGLE_LOGIN: `${USERS}/accounts/google/`,
  AUDIO_TRANSCRIPTIONS: `${CORE}/audio-transcriptions/`,
  AUDIO_RETRANSCRIBE: (id) => `${CORE}/audio-transcriptions/${id}/re-transcribe/`,
  TRANSCRIPTION_SPEAKERS: `${CORE}/transcription-speakers/`,
  TRANSCRIPTION_SPEAKER_DETAIL: (id) => `${CORE}/transcription-speakers/${id}/`,
  AUDIO_TRANSCRIPT_DETAIL: (id) => `${CORE}/audio-transcriptions/${id}/`,
  AUDIO_TRANSCRIPT_DELETE: (id) => `${CORE}/audio-transcriptions/${id}/`,
  OVERALL_AUDIO_TRANSCRIPTION_STATE: (id) => `${CORE}/audio-transcriptions/${id}/overall-state/`,
  TRANSCRIPTION_STATUS: (id) => `${CORE}/audio-transcriptions/${id}/transcription-status/`,
  SUMMARY_STATUS: (id) => `${CORE}/transcription-summaries/${id}/summary-status/`,
  TRANSCRIPTION_SUMMARIES: `${CORE}/transcription-summaries/`,
  TRANSCRIPTION_SUMMARY_DETAIL: (id) => `${CORE}/transcription-summaries/${id}/`,
  TRANSCRIPTION_SUMMARY_EDIT: (id) => `${CORE}/transcription-summaries/${id}/edit/`,
  MEETING_NOTES_SHARE: (id) => `${CORE}/transcription-summaries/${id}/share/`,
  TRANSCRIPTION_REMAINING_TIME: (id) => `${CORE}/audio-transcriptions/${id}/remaining-time/`,
  SUMMARY_REMAINING_TIME: (id) => `${CORE}/transcription-summaries/${id}/remaining-time/`,
  DISCONNECT_GOOGLE_MEET: `${USERS}/disconnect-google-meet/`,
  CONNECT_ZOOM: `${USERS}/connect-zoom/`,
  DISCONNECT_ZOOM: `${USERS}/disconnect-zoom/`,

  // Meeting Configuration APIs
  EVENTS: `${EVENTS}/`,
  STATUS: (id) => `${EVENTS}/${id}/status/`,
  STATUS_ALL: `${EVENTS}/status-all/`,
  SETTINGS: (id) => `${EVENTS}/${id}/settings/`,
  RATING: (id) => `${CORE}/transcription-summaries/${id}/rating/`,

  // MOM
  TRANSCRIPTION_MOM_DETAIL: (id) => `${CORE}/transcription-moms/${id}/`,
  MOM_STATUS: (id) => `${CORE}/transcription-moms/${id}/mom-status/`,

};
