import { apiUtils, endpoints } from 'api';

export const api = {
  userDetail: async (id) => {
    return await apiUtils.get(endpoints.USER_DETAIL(id));
  },
  login: async (data) => {
    return await apiUtils.post(endpoints.LOGIN, data, false);
  },
  googleLogin: async (data) => {
    return await apiUtils.post(endpoints.GOOGLE_LOGIN, data, false);
  },
  connectZoom: async (data) => {
    return await apiUtils.post(endpoints.CONNECT_ZOOM, data);
  },
  disconnectZoom: async (data) => {
    return await apiUtils.post(endpoints.DISCONNECT_ZOOM, data);
  },
  audioRetranscribe: async (id) => {
    return await apiUtils.patch(endpoints.AUDIO_RETRANSCRIBE(id), {});
  },
  audioTranscriptList: async (params) => {
    return await apiUtils.get(endpoints.AUDIO_TRANSCRIPTIONS, params);
  },
  audioTranscriptDetail: async (id) => {
    return await apiUtils.get(endpoints.AUDIO_TRANSCRIPT_DETAIL(id));
  },
  overallAudioTranscriptionState: async (id) => {
    return await apiUtils.get(endpoints.OVERALL_AUDIO_TRANSCRIPTION_STATE(id));
  },
  transcriptionStatus: async (id) => {
    return await apiUtils.get(endpoints.TRANSCRIPTION_STATUS(id));
  },
  summaryStatus: async (id) => {
    return await apiUtils.get(endpoints.SUMMARY_STATUS(id));
  },
  audioTranscriptionUpdate: async ({id, data}) => {
    return await apiUtils.patch(endpoints.AUDIO_TRANSCRIPT_DETAIL(id), data);
  },
  audioTranscriptionDelete: async ({id}) => {
    return await apiUtils.delete(endpoints.AUDIO_TRANSCRIPT_DELETE(id));
  },
  transcriptionSpeakerUpdate: async ({id, data}) => {
    return await apiUtils.patch(endpoints.TRANSCRIPTION_SPEAKER_DETAIL(id), data);
  },
  transcriptionSummaryCreate: async (id) => {
    return await apiUtils.patch(endpoints.TRANSCRIPTION_SUMMARY_DETAIL(id), {});
  },
  transcriptionSummaryEdit: async ({ id, data }) => {
    return await apiUtils.patch(endpoints.TRANSCRIPTION_SUMMARY_EDIT(id), data);
  },
  transcriptionSummaryList: async () => {
    return await apiUtils.get(endpoints.TRANSCRIPTION_SUMMARIES);
  },
  meetingNotesShare: async (id) => {
    return await apiUtils.post(endpoints.MEETING_NOTES_SHARE(id));
  },
  transcriptionRemainingTime: async (id) => {
    return await apiUtils.get(endpoints.TRANSCRIPTION_REMAINING_TIME(id), {}, true, false);
  },
  summaryRemainingTime: async (id) => {
    return await apiUtils.get(endpoints.SUMMARY_REMAINING_TIME(id), {}, true, false);
  },
  disconnectGoogleMeet: async (id) => {
    return await apiUtils.post(endpoints.DISCONNECT_GOOGLE_MEET, {});
  },

  // Meeting Configuration APIs
  events: async (params) => {
    return await apiUtils.get(endpoints.EVENTS, params);
  },
  status: async ({id, data}) => {
    return await apiUtils.patch(endpoints.STATUS(id), data);
  },
  statusAll: async (data) => {
    return await apiUtils.patch(endpoints.STATUS_ALL, data);
  },
  settings: async ({id, data}) => {
    return await apiUtils.patch(endpoints.SETTINGS(id), data);
  },

  // Rating
  rating: async ({id, data}) => {
    return await apiUtils.patch(endpoints.RATING(id), data, false);
  },

  //MOM
  transcriptionMOMCreate: async (id) => {
    return await apiUtils.patch(endpoints.TRANSCRIPTION_MOM_DETAIL(id), {});
  },
  momStatus: async (id) => {
    return await apiUtils.get(endpoints.MOM_STATUS(id));
  },
};
