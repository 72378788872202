import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Star, StarFill } from 'react-bootstrap-icons';
import { MAX_RATING } from 'common/constants';
import { generalExtraActions } from 'reduxStore/actions';
import 'components/rating/_rating.scss';

function Rating({ rating, id, setShowRatingModal, size = 20 }) {
  const [hover, setHover] = useState(null);
  const dispatch = useDispatch();

  const handleRatingSubmit = (value) => {
    try {
      dispatch(generalExtraActions.rating({ id, data: { rating: value } })).then((response) => {
        if (response?.type === 'general/rating/fulfilled') {
          dispatch(generalExtraActions.audioTranscriptDetail(id)).then((response) => {
            setShowRatingModal(true);
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='rating'>
      {rating ? null : <span className='rating-text'>Rate this summary:</span>}
      <div className='rating-stars'>
        {[...Array(MAX_RATING)].map((_, index) => {
          const starValue = index + 1;
          const isFilled = rating !== null ? starValue <= rating : starValue <= hover;
          return (
            <span
              key={starValue}
              onMouseEnter={() => rating === null && setHover(starValue)}
              onMouseLeave={() => rating === null && setHover(null)}
              onClick={() => rating === null && handleRatingSubmit(starValue)}
              className={`${rating ? '' : 'show-cursor'}`}
            >
              {isFilled ? (
                <StarFill fill={'#6938EF'} size={size} key={starValue} />
              ) : (
                <Star fill={'#697586'} size={size} key={starValue} />
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default Rating;
