import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generalExtraActions } from "../../reduxStore/actions";
import { routes } from "common";
import { Loader } from "components";

function Rating() {
  const {id, rating} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);


  useEffect(() => {
    dispatch(generalExtraActions.rating({id, data: {rating: parseInt(rating)}})).then((response) => {
      if (response?.type === 'general/rating/fulfilled') {
        const meetingURL = `${routes.MEETINGS}/${id}`;
        if (isUserLoggedIn) {
          navigate(meetingURL, {state: {rating: response?.payload.rating}});
        } else {
          navigate('/', {state: {rating: response?.payload.rating, from: {pathname: meetingURL}}})
        }
      } else {
        navigate(routes.LANDING);
      }
    });
  }, []);

  return (<Loader/>);
}

export default Rating;
