import React, { useEffect, useRef, useState } from 'react';
import { IconFile } from 'assets/icons';
import {
  COMPLETED,
  FAILED,
  IN_PROGRESS,
  IN_PROGRESS_FETCH_INTERVAL,
  IN_QUEUE,
  PROGRESS_STOP_AT,
} from 'common/constants';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from 'components';
import 'components/sidebarFile/_sidebarFile.scss';
import { useDispatch } from 'react-redux';
import { generalExtraActions } from 'reduxStore/actions';
import { routes, utils } from 'common';
import { getAudioTranscriptions } from "pages/meetings/helpers";

function SidebarFile({currentId, fileData, query, meetingTypes, currentTab}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = fileData.id;
  const link = `${routes.MEETINGS}/${id}`

  const timeInfo = utils.getFileTimeInfo(currentTab, fileData);

  const [remainingTime, setRemainingTime] = useState(Math.round(timeInfo.remaining_time));
  const estimatedTime = Math.round(timeInfo.estimated_time);

  const isActive = id === currentId;
  const status = utils.getFileStatus(currentTab, fileData);
  const [newStatus, setNewStatus] = useState(status);
  const isInProgress = newStatus === IN_PROGRESS;
  const isInQueue = newStatus === IN_QUEUE;

  const [progress, setProgress] = useState(0);

  const timeIntervalRef = useRef(null);
  const statusIntervalRef = useRef(null);

  const fetchStatus = async () => {
    try {
      setNewStatus(await utils.getFileNewStatus(currentTab, id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = () => {
    if (!isActive) {
      navigate(link);
    }
  };

  useEffect(() => {
    setNewStatus(status);
    setRemainingTime(Math.round(timeInfo.remaining_time));
  }, [currentTab])

  useEffect(() => {
    if (!isInProgress) return;
    if (estimatedTime && remainingTime) {
      let newProgress = Math.round(100 - (remainingTime / estimatedTime) * 100);
      if (newProgress > PROGRESS_STOP_AT) {
        newProgress = PROGRESS_STOP_AT;
      }
      if (newProgress > progress) {
        setProgress(newProgress);
      }
      if (Math.round(estimatedTime - remainingTime) % IN_PROGRESS_FETCH_INTERVAL === 0) {
        fetchStatus();
      }
    }
  }, [remainingTime]);

  useEffect(() => {
    if (newStatus !== status) {
      getAudioTranscriptions(dispatch, query, meetingTypes);
      if (newStatus === FAILED || newStatus === COMPLETED) {
        if (isActive) {
          dispatch(generalExtraActions.audioTranscriptDetail(id));
        }
        clearInterval(statusIntervalRef.current);
        clearInterval(timeIntervalRef.current);
      }
    }
  }, [newStatus]);

  useEffect(() => {
    if (isInProgress || isInQueue) {
      statusIntervalRef.current = setInterval(() => {
        fetchStatus();
      }, utils.getStatusFetchInterval(estimatedTime));
    }
    if (isInProgress) {
      timeIntervalRef.current = setInterval(() => {
        setRemainingTime((remainingTime) => Math.round(remainingTime - 2));
      }, 2000);
    }

    return () => {
      clearInterval(statusIntervalRef.current);
      clearInterval(timeIntervalRef.current);
    };
  }, []);

  return (
    <li className={`transcription-file ${isActive ? 'active' : ''}`}>
      <a className='file-link' onClick={handleFileChange}>
        <div>
          <img src={IconFile} alt='file'/>
          <b>{fileData.title}</b>
        </div>
        <span>{utils.getFileNewStatusLabel(currentTab, newStatus)}</span>
      </a>
      {isInProgress && <ProgressBar progress={progress} classes={'pb-dark'}/>}
    </li>
  );
}

export default SidebarFile;
