import React from 'react';
import 'components/toggle/_simpleToggle.scss';

function SimpleToggle({label, isEnabled, checked, onChange}) {
  return (
    <div className={`auto-share-toggle ${isEnabled ? '' : 'disabled'}`}>
      <label className={`toggle ${checked ? 'active' : ''}`}>
        <input
          type='checkbox'
          checked={checked}
          onChange={onChange}
        />
        <span className='toggle-slider'></span>
      </label>
      <span>{label}</span>
    </div>
  );
}

export default SimpleToggle;
