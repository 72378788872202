import React, { useEffect, useState } from 'react';
import 'components/sidebar/_sidebar.scss';
import { IconChevronDown, IconChevronUp } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { BrickFilter, SearchBar, SidebarFile, Speaker } from 'components';
import { generalExtraActions } from 'reduxStore/actions';
import { utils } from 'common';
import { FILE_TYPES, TAB_CODES } from 'common/constants';
import { getAudioTranscriptions } from "pages/meetings/helpers";

function Sidebar({currentId, absoluteIndex, query, meetingTypes, setQuery, setMeetingTypes, currentTab}) {
  const dispatch = useDispatch();
  const {audioTranscriptions, transcriptionData} = useSelector((state) => state.general);
  const [showFiles, setShowFiles] = useState(true);
  const [title, setTitle] = useState('');
  const [currentSpeaker, setCurrentSpeaker] = useState(null);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleMeetingTypeChange = (event) => {
    dispatch(generalExtraActions.audioTranscriptionUpdate({
      id: currentId, data: {file_type: event.target.value},
    }));
  };

  const handleTitleSave = () => {
    dispatch(generalExtraActions.audioTranscriptionUpdate({
      id: currentId, data: {title: title},
    })).then((response) => {
      if (response?.type === 'general/audioTranscriptionUpdate/fulfilled') {
        getAudioTranscriptions(dispatch, query, meetingTypes);
      }
    });
  };

  useEffect(() => {
    setTitle(transcriptionData?.title);
  }, [transcriptionData?.title]);

  useEffect(() => {
    const segment = utils.isObjectEmpty(transcriptionData) ? null : transcriptionData?.original_transcription ? transcriptionData?.original_transcription[absoluteIndex] : null;
    setCurrentSpeaker(segment?.speaker);
  }, [absoluteIndex]);

  return (<div className='sidebar'>
    <div className='sidebar__filters'>
      <SearchBar placeholder='Search for a meeting' onSearch={setQuery}/>
      <BrickFilter choices={FILE_TYPES} onFilterChange={setMeetingTypes}/>
    </div>
    <div className='files'>
      <h2
        id='accord'
        onClick={() => setShowFiles(!showFiles)}
      >
        Meetings
        <span className='right-arrow'>
            <img src={showFiles ? IconChevronUp : IconChevronDown} width='8px'/>
          </span>
      </h2>
      <ul className={showFiles ? 'opened' : ''}>
        {audioTranscriptions?.map((audioTranscription) => {
          return (<SidebarFile
            key={`${audioTranscription.id}-${audioTranscription.transcription_status}-${audioTranscription.summary_status}-${audioTranscription.mom_status}`}
            currentId={currentId} fileData={audioTranscription} query={query} meetingTypes={meetingTypes}
            currentTab={currentTab}
          />);
        })}
      </ul>
    </div>
    {
      currentId &&
      <>
        <div className='title-section'>
          <h2 className='mt10'>
            Title
            <span>Click to edit</span>
          </h2>
          <div className='flex'>
        <span className='speaker'>
          <input type='text' name='title' value={title} onChange={handleTitleChange}/>
          <button
            disabled={title === transcriptionData?.title || title?.trim() === ''}
            onClick={handleTitleSave}
          >
            Save
          </button>
        </span>
          </div>
        </div>
        <div className='meeting-type-section'>
          <h2 className='mt10'>
            Meeting Type
            <span>Select an option</span>
          </h2>
          <div className='flex'>
            <select
              onChange={(e) => handleMeetingTypeChange(e)}
              value={transcriptionData?.file_type}
              className='meeting-options'
            >
              {Object.entries(FILE_TYPES).map(([key, value]) => (
                <option key={key} value={key} selected={transcriptionData?.file_type === key}>
                  {value}
                </option>))}
            </select>
          </div>
        </div>
        {currentTab === TAB_CODES.TRANSCRIPTION && <div className='speakers'>
          {transcriptionData?.speakers?.length > 0 && (<>
            <h2 className='mt10'>
              Speakers
              <span>Click to edit</span>
            </h2>
            <div className='flex'>
              {transcriptionData?.speakers?.map((speaker) => {
                return (<Speaker
                  key={speaker.id}
                  transcriptionId={currentId}
                  speaker={speaker}
                  isActive={currentSpeaker === speaker.id}
                />);
              })}
            </div>
          </>)}
        </div>}
      </>
    }
  </div>);
}

export default Sidebar;
