import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { routes } from 'common';
import { Loader, RatingModal } from 'components';
import { authExtraActions } from 'reduxStore/actions';
import { IconGoogle, IconTick } from 'assets/icons';
import { Logo, ImgLanding } from 'assets/images';
import { GOOGLE_CALENDAR_SCOPE, MARKETING_SITE_URL } from 'common/constants';
import 'pages/landing/_landing.scss';

function Landing({ signup }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const rating = location.state?.rating;
  const navigate = useNavigate();
  const { loading, isUserLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate(routes.MEETINGS);
    }
  }, []);

  const [showRatingModal, setShowRatingModal] = useState(!!rating);

  const login = useGoogleLogin({
    onSuccess: (response) => {
      dispatch(authExtraActions.googleLogin(response)).then((response) => {
        if (response?.type === 'auth/googleLogin/fulfilled') {
          const redirectPath = location.state?.from?.pathname;
          if (redirectPath) {
            navigate(redirectPath, { replace: true });
          } else {
            navigate(routes.MEETINGS);
          }
        }
      });
    },
    scope: GOOGLE_CALENDAR_SCOPE,
    flow: 'auth-code',
  });

  return (
    <div className='landing'>
      {loading && <Loader />}
      <div className='custom-container'>
        <div className='logo'>
          <Link to={MARKETING_SITE_URL}>
            <img src={Logo} alt={'Textalize Logo'} />
          </Link>
        </div>
        <div className='content'>
          <div className='content-text'>
            <p className='heading'>{signup ? 'Create your account' : 'Log in'}</p>
            <p className='content-subtext'>
              {signup
                ? 'Sync your work calendar to start using Textalize'
                : 'Welcome back! Sign in to continue.'}
            </p>
          </div>
          <div className='social-btn'>
            <a onClick={() => login()}>
              <img src={IconGoogle} alt='google' height={24} loading='lazy' />
              <span>Sign {signup ? 'up with Google' : 'in with Google'}</span>
            </a>
          </div>
          {/* {signup ? (
            <div className='calendar-info'>Why does Textalize need calendar access?</div>
          ) : null} */}
          <div className='link'>
            {signup ? (
              <>
                Already have an account? <span onClick={() => navigate('/')}>Log in</span>
              </>
            ) : (
              <>
                Don’t have an account? <span onClick={() => navigate(routes.SIGNUP)}>Sign up</span>
              </>
            )}
          </div>
        </div>
        <div className='footer-content'>
          {signup ? (
            <div className='signup-footer'>
              By using Textalize, you agree to our{' '}
              <a
                href='https://textalize.ai/privacy_policy.html'
                target='_blank'
                rel='noopener noreferrer'
              >
                privacy policy
              </a>{' '}
              and{' '}
              <a
                href='https://textalize.ai/privacy_policy.html'
                target='_blank'
                rel='noopener noreferrer'
              >
                terms and conditions{' '}
              </a>
            </div>
          ) : null}
        </div>
      </div>
      <div className='custom-container second'>
        <div className='hero-img'>
          <img src={ImgLanding} alt='hero' loading='lazy' />
          <div className='hero-text'>
            <p className='headline'>
              Make your meetings more impactful with Textalize meeting assistant
            </p>
            <div className='points'>
              <p className='point'>
                <span>
                  <img src={IconTick} alt={'tick'} />
                </span>
                Connect your Google calendar to invite bot assistants to Zoom Meetings and Google
                Meet.
              </p>
              <p className='point'>
                <span>
                  <img src={IconTick} alt={'tick'} />
                </span>
                Get summaries for your bilingual meetings in the format of your choice.
              </p>
              <p className='point'>
                <span>
                  <img src={IconTick} alt={'tick'} />
                </span>
                Get comprehensive notes and actionable insights from your discussions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <RatingModal
        rating={rating}
        show={showRatingModal}
        handleClose={() => setShowRatingModal(false)}
      />
    </div>
  );
}

export default Landing;
